import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  MANAGE_CLASS,
  MANAGE_CLASS_API_URL,
  GET_ALL_MANAGE_CLASS_API_URL,
  ADD_CLASS_COURSE_API_URL,
  ADD_CLASS_RESOURCE_API_URL,
  GET_TAGGED_CLASS_COURSE_TEACHER_API_URL,
  GET_TAGGED_CLASS_RESOURCES_API_URL,
  ADD_CLASS_STUDENT_API_URL,
  GET_OTHER_CLASS_API_URL,
  GET_GRADE_STUDENT_API_URL,
  GET_OTHER_CLASS_STUDENT_API_URL,
  DELETE_CLASS_STUDENT_API_URL,
  DELETE_CLASS_COURSE_API_URL,
  CLASS_COURSE_FILTER_API_URL,
  CLASS_STUDENT_FILTER_API_URL,
  SEND_EMAIL,
  SAVE_GOOGLE_TOKEN,
  GET_CLASS_COURSE_DATA_API_URL,
  GET_CLASS_COURSE_API_URL,
  SAVE_LESSON_TOPIC,
  ADD_CLASS_COURSE_WEIGHTAGE_API_URL,
  DELETE_CLASS_COURSE_Teacher_API_URL,
  ADD_CLASS_COURSE_SCHEDULE_ACTIVITY_API_URL,
  GET_CLASS_COURSE_STRANDS_API_URL,
  SAVE_CLASS_COURSES_STRANDS_API_URL,
  GET_CLASS_COURSE_WITHOUT_STRAND_API_URL,
  GET_STRANDS_COURSES_API_URL,
  DELETE_CLASS_RESOURCE_API_URL,
  UPDATE_COURSE_STRANDS_SORT_API_URL,
  DELETE_COURSE_STRANDS_API_URL,
  ADD_COURSE_STRANDS_API_URL,
  SAVE_ERS_URL,
  GET_ERS_URL,
} from "constants/AppConstants";
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from "services/CommonService/CommonService";
import IntlMessage from "components/util-components/IntlMessage";
import moment from "moment";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(
  localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE)
);

export const initialState = {
  ManageClassErrors: {},
  ClassCourseErrors: {},
  ClassCourseStrandErrors: {},
  TagClassCourseStrandErrors: {},
  ClassCourseScheduleActivityErrors: {},
  ClassCourseScheduleErrors: {},
  ClassResourceErrors: {},
  ClassStudentErrors: {},
  classCourseStrandsIds: [],
  ConversationErrors: {},
  Erssors: {},
  permission: permission?.manage_class ?? [],
  ManageClassShowMessage: null,
  ClassCourseShowMessage: null,
  ClassCourseStrandsShowMessage: null,
  ClassCourseScheduleActivityShowMessage: null,
  ClassCourseShowScheduleMessage: null,
  ClassResourceShowMessage: null,
  ClassStudentShowMessage: null,
  ConversationShowMessage: null,
  ErsShowMessage: null,
  ManageClassResult: [],
  ConversationButtonSpinner: false,
  ErsButtonSpinner: false,
  ClassCourseButtonSpinner: false,
  ClassCourseScheduleActivityButtonSpinner: false,
  ClassCourseScheduleButtonSpinner: false,
  ClassResourceButtonSpinner: false,
  ManageClassButtonSpinner: false,
  ClassStudentButtonSpinner: false,
  ManageClassTableLoading: true,
  ClassCourseStrandsTableLoading: true,
  ErsTableLoading: true,
  ClassCourseStrandsLoading: true,
  ClassCourseWithoutStrandsLoading: true,
  SaveClassCourseStrandsLoading: true,
  ManageClassAddDrawer: false,
  ClassOverviewDrawer: true,
  ConversationAddDrawer: false,
  ErsAddDrawer: false,
  AttendanceDrawer: false,
  classCourseDrawer: false,
  courseScheduleDrawer: false,
  classResourceDrawer: false,
  ClassCourseWeightageButtonSpinner: false,
  ClassCourseStrandsButtonSpinner: false,
  ClassCourseWeightageDrawer: false,
  ClassCourseStrandsDrawer: false,
  TagClassCourseStrandsToOtherDrawerStatus: false,
  TagClassCourseStrandsDrawer: false,
  classCourseViewDrawer: false,
  classStudentDrawer: false,
  ManageClassButtonAndModelLabel: setLocale("classes.add"),
  AddClassCourseTitle: setLocale("classes.addClassCourse"),
  AddClassCourseWeightageTitle: setLocale("classes.addClassCourseWeightage"),
  AddClassCourseStrandsTitle: setLocale("classes.addClassCourseStrands"),
  AddClassCourseScheduleTitle: setLocale("classes.addClassCourseSchedule"),
  AddClassResourceTitle: setLocale("classes.addClassResource"),
  AddClassStudentTitle: setLocale("classes.addClassStudents"),
  ConversationButtonAndModelLabel: setLocale("classes.conversation"),
  ErsButtonAndModelLabel: setLocale("classes.earlyReadingScreening"),
  TagStrandsClassCourseTitle: setLocale("classes.TagStrandsClassCourseTitle"),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  strandsTablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  ErsTablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {
    schoolYear: null,
    gradeLevel: null,
    searchString: null,
    organization_id: null,
  },
  StrandsSorting: {},
  strandsFilters: {},
  courseSchedule: [],
  selectCourseStrands: [],
  ManageClassEditData: {},
  ClassCourseEditData: [],
  ClassCourseScheduleEditData: [],
  ClassCoursesData: [],
  ClassResourceData: [],
  ClassCourses: [],
  strandsCourses: [],
  withoutStrandsCourses: [],
  courseScheduleSlots: [],
  course_schedule: [],
  classCourseStrands: [],
  Ers: [],
  ClassStudentsData: [],
  ViewManageClassData: {
    class_courses_count: null,
    class_student_count: null,
    enc_id: null,
    start_date: null,
    end_date: null,
    grade_level: null,
    id: null,
    is_same_room: null,
    label: null,
    class_courses_teachers: [],
    courseSchedule: [],
    home_teacher_name: "",
    name: null,
    organization_grade_level_id: null,
    organization_id: null,
    school_room: null,
    school_room_id: null,
    school_year: null,
    school_year_id: null,
    teacher_id: null,
    totalDayLefts: null,
    value: null,
  },
  ViewManageClassLoader: true,
  taggedClassCourseTeachers: [],
  taggedClassCourseResources: [],

  OtherClassesData: [],
  gradeStudents: [],
  OtherClassStudents: [],
  classCourseSchedule: [],
  classCoursesSchedule: [],
  selectedRowKeys: [],
  classCourseScheduleDrawer: true,
  classCourseLessonDrawer: true,
  classCourseGradeDrawer: false,
  classCourseAssignmentDrawer: false,
  classCourseSearchString: "",
  classCourseLoader: false,
  classStudentSearchString: "",
  classStudentLoader: false,
  conversationAttachment: null,

  selectedLessonPlan: null,
  openTopicModal: false,
};

export const saveLessonTopic = createAsyncThunk(
  "saveLessonTopic",
  async (data) => {
    try {
      return await CommonService.postData(data, SAVE_LESSON_TOPIC);
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
  }
);

export const createManageClass = createAsyncThunk(
  "createManageClass",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(
        data,
        MANAGE_CLASS_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getManageClass = createAsyncThunk(
  "getManageClass",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_ALL_MANAGE_CLASS_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewManageClass = createAsyncThunk(
  "viewManageClass",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, MANAGE_CLASS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteManageClass = createAsyncThunk(
  "deleteManageClass",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(
        data,
        MANAGE_CLASS_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getTaggedClassCourseTeachers = createAsyncThunk(
  "getTaggedClassCourseTeachers",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_TAGGED_CLASS_COURSE_TEACHER_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getTaggedClassResources = createAsyncThunk(
  "getTaggedClassResources",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_TAGGED_CLASS_RESOURCES_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const get_class_courses = createAsyncThunk(
  "get_class_courses",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_CLASS_COURSE_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getClassCourseData = createAsyncThunk(
  "getClassCourseData",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_CLASS_COURSE_DATA_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const createClassCourse = createAsyncThunk(
  "createClassCourse",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        ADD_CLASS_COURSE_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const createClassResource = createAsyncThunk(
  "createClassResource",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        ADD_CLASS_RESOURCE_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const updateClassCourseWeightage = createAsyncThunk(
  "updateClassCourseWeightage",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        ADD_CLASS_COURSE_WEIGHTAGE_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const addClassCourseStrands = createAsyncThunk(
  "addClassCourseStrands",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        ADD_COURSE_STRANDS_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const addClassStudent = createAsyncThunk(
  "addClassStudent",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        ADD_CLASS_STUDENT_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOtherClasses = createAsyncThunk(
  "getOtherClasses",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_OTHER_CLASS_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getGradeStudents = createAsyncThunk(
  "getGradeStudents",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_GRADE_STUDENT_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOtherClassStudent = createAsyncThunk(
  "getOtherClassStudent",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_OTHER_CLASS_STUDENT_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStrandsCourses = createAsyncThunk(
  "getStrandsCourses",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_STRANDS_COURSES_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getClassCourseWithoutStrand = createAsyncThunk(
  "getClassCourseWithoutStrand",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_CLASS_COURSE_WITHOUT_STRAND_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const saveClassCoursesStrands = createAsyncThunk(
  "saveClassCoursesStrands",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        SAVE_CLASS_COURSES_STRANDS_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getClassCourseStrands = createAsyncThunk(
  "getClassCourseStrands",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_CLASS_COURSE_STRANDS_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getErs = createAsyncThunk("getErs", async (data) => {
  try {
    const response = await CommonService.getAllPost(data, GET_ERS_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const deleteClassStudent = createAsyncThunk(
  "deleteClassStudent",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        DELETE_CLASS_STUDENT_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteClassCourse = createAsyncThunk(
  "deleteClassCourse",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        DELETE_CLASS_COURSE_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const classCourseFilter = createAsyncThunk(
  "classCourseFilter",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        CLASS_COURSE_FILTER_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const classStudentFilter = createAsyncThunk(
  "classStudentFilter",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        CLASS_STUDENT_FILTER_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const calenderEvent = createAsyncThunk("calenderEvent", async (data) => {
  try {
    const response = await CommonService.getAllPost(
      data,
      ADD_CLASS_COURSE_SCHEDULE_ACTIVITY_API_URL
    );
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const updateCourseStrandsSort = createAsyncThunk(
  "updateCourseStrandsSort",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        UPDATE_COURSE_STRANDS_SORT_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteClassCourseStrands = createAsyncThunk(
  "deleteClassCourseStrands",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        DELETE_COURSE_STRANDS_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const saveGoogleToken = createAsyncThunk(
  "saveGoogleToken",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, SAVE_GOOGLE_TOKEN);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const sendEmail = createAsyncThunk("sendEmail", async (data) => {
  try {
    const response = await CommonService.createAndUpdate(data, SEND_EMAIL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const saveErs = createAsyncThunk("saveErs", async (data) => {
  try {
    const response = await CommonService.createAndUpdate(data, SAVE_ERS_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const deleteClassCourseTeacher = createAsyncThunk(
  "deleteClassCourseTeacher",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        DELETE_CLASS_RESOURCE_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteClassResource = createAsyncThunk(
  "deleteClassResource",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        DELETE_CLASS_RESOURCE_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageManageClassSlice = createSlice({
  name: MANAGE_CLASS,
  initialState,
  reducers: {
    setSelectedLessonPlan: (state, action) => {
      state.selectedLessonPlan = action.payload.data;
      state.openTopicModal = action.payload.status;
    },
    setClassCourseSchedule: (state, action) => {
      state.classCoursesSchedule = action.payload;
    },
    ManageClassAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.ManageClassButtonSpinner = false;
      }
      state.ManageClassErrors = {};
      state.ManageClassAddDrawer = action.payload;
      state.ManageClassEditData = [];
      state.ManageClassButtonAndModelLabel = setLocale("classes.add");
    },
    ManageClassEditWithDrawerStatus: (state, action) => {
      state.ManageClassErrors = {};
      state.ManageClassAddDrawer = true;
      state.ManageClassEditData = {
        ...state.ManageClassEditData,
        ...action.payload,
        class_courses_teachers:
          state.ViewManageClassData?.class_courses_teachers,
      };

      state.ManageClassButtonAndModelLabel = setLocale("classes.edit");
    },
    ManageConversationDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ConversationButtonSpinner = false;
      }
      state.ConversationErrors = {};
      state.conversationAttachment = null;
      state.ConversationAddDrawer = action.payload.status;
      state.ManageClassEditData = action.payload.data;
      state.ConversationButtonAndModelLabel = setLocale("classes.conversation");
    },
    ManageErsDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ErsButtonSpinner = false;
      }
      state.ErsErrors = {};
      state.ErsAddDrawer = action.payload.status;
      state.ManageClassEditData = action.payload.data;
      state.ErsButtonAndModelLabel = setLocale("classes.earlyReadingScreening");
    },
    ClassOverviewDrawerStatus: (state, action) => {
      state.ClassOverviewDrawer = action.payload;
    },
    AttendanceDrawerStatus: (state, action) => {
      state.AttendanceDrawer = action.payload;
    },
    ClassCourseDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ClassCourseButtonSpinner = false;
      }
      state.ClassCourseErrors = {};
      state.classCourseDrawer = action.payload.status;
      state.ClassCourseEditData = action.payload.data;
      state.AddClassCourseTitle =
        Object.keys(action.payload.data).length > 0
          ? setLocale("classes.updateClassCourse")
          : setLocale("classes.addClassCourse");
    },

    ClassCourseScheduleStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ClassCourseScheduleButtonSpinner = false;
        state.courseScheduleSlots = [];
      }
      state.ClassCourseScheduleErrors = {};
      state.courseScheduleDrawer = action.payload.status;
      state.ClassCourseScheduleEditData = action.payload.data;
      state.AddClassCourseScheduleTitle =
        Object.keys(action.payload.data).length > 0
          ? setLocale("classes.updateClassCourseSchedule")
          : setLocale("classes.addClassCourseSchedule");
    },
    ClassResourceDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ClassResourceButtonSpinner = false;
      }
      state.ResourceCourseErrors = {};
      state.classResourceDrawer = action.payload.status;
      state.ClassCourseEditData = action.payload.data;
      state.AddClassResourceTitle =
        action.payload.data.length > 0
          ? setLocale("classes.updateClassResource")
          : setLocale("classes.addClassResource");
    },
    ClassCourseWeightageDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ClassCourseWeightageButtonSpinner = false;
      }
      state.ClassCourseWeightageDrawer = action.payload.status;
      state.ClassCourseEditData = action.payload.data;
    },
    ClassCourseStrandsDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ClassCourseStrandsButtonSpinner = false;
      }
      state.ClassCourseStrandErrors = {};
      state.ClassCourseStrandsDrawer = action.payload.status;
      state.ClassCourseEditData = action.payload.data;
    },
    TagClassCourseStrandsDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ClassCourseStrandsButtonSpinner = false;
      }
      state.TagClassCourseStrandErrors = {};
      state.TagClassCourseStrandsDrawer = action.payload.status;
      state.ClassCourseEditData = action.payload.data;
    },
    ClassStudentDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ClassStudentButtonSpinner = false;
      }
      state.classStudentDrawer = action.payload.status;
    },
    ClassCourseViewDrawerStatus: (state, action) => {
      state.classCourseScheduleDrawer = action.payload.status;
      state.classCourseViewDrawer = action.payload.status;
      state.ClassCourseEditData = action.payload?.data ?? [];
      state.classCoursesSchedule = action.payload?.data?.course_schedule ?? [];
    },
    resetStates: (state, action) => {
      state.ManageClassAddDrawer = false;
      state.ClassOverviewDrawer = true;
      state.ConversationAddDrawer = false;
      state.AttendanceDrawer = false;
      state.classCourseDrawer = false;
      state.classResourceDrawer = false;
      state.classCourseViewDrawer = false;
      state.classStudentDrawer = false;
    },
    ClassCourseScheduleDrawerStatus: (state, action) => {
      state.classCourseScheduleDrawer = action.payload.status;
      state.classCoursesSchedule = action.payload.data;

      state.classCourseLessonDrawer = false;
      state.classCourseGradeDrawer = false;
      state.classCourseAssignmentDrawer = false;
    },
    ClassCourseLessonDrawerStatus: (state, action) => {
      state.classCourseLessonDrawer = action.payload.status;

      state.classCourseScheduleDrawer = false;
      state.classCourseGradeDrawer = false;
      state.classCourseAssignmentDrawer = false;
    },
    ClassCourseGradeDrawerStatus: (state, action) => {
      state.classCourseGradeDrawer = action.payload.status;

      state.classCourseScheduleDrawer = false;
      state.classCourseLessonDrawer = false;
      state.classCourseAssignmentDrawer = false;
    },
    ClassCourseAssignmentDrawerStatus: (state, action) => {
      state.classCourseAssignmentDrawer = action.payload.status;

      state.classCourseScheduleDrawer = false;
      state.classCourseLessonDrawer = false;
      state.classCourseGradeDrawer = false;
    },
    updateClassCourseString: (state, action) => {
      state.classCourseSearchString = action.payload;
    },
    updateClassStudentString: (state, action) => {
      state.classStudentSearchString = action.payload;
    },
    setSelectedRowKeys: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    updateSortFilters: (state, action) => {
      state.filter.searchString = action.payload?.searchString;
      state.filter.schoolYear = action.payload?.schoolYear;
      state.filter.gradeLevel = action.payload?.gradeLevel;
      state.filter.organization_id = action.payload?.organization_id;
    },
    setConversationAttachment: (state, action) => {
      state.conversationAttachment = action.payload;
    },
    setTeacherCourses: (state, action) => {
      state.ClassCoursesData = action.payload;
    },
    setCourseScheduleSlots: (state, action) => {
      // Initialize the day slot with recurrence_frequency and timeSlots
      state.courseScheduleSlots = {
        ...state.courseScheduleSlots,
        [action.payload.day]: {
          recurrence_frequency: action.payload?.recurrence_frequency,
          timeSlots: [],
        },
      };

      // Loop to add time slots based on recurrence_frequency
      for (
        let index = 0;
        index < action.payload.recurrence_frequency;
        index++
      ) {
        state.courseScheduleSlots[action.payload.day].timeSlots.push({
          startTime:
            action.payload?.slotDetails &&
            action.payload?.slotDetails?.[index]?.start_time !== undefined
              ? action.payload?.slotDetails?.[index]?.start_time
              : moment().format("HH:mm"),
          durationMinutes:
            action.payload?.slotDetails &&
            action.payload?.slotDetails?.[index]?.duration_minutes !== undefined
              ? action.payload?.slotDetails?.[index]?.duration_minutes
              : 45,
        });
      }
    },

    setCourseScheduleTimeSlots: (state, action) => {
      state.courseScheduleSlots[action.payload.day].timeSlots[
        action.payload.index
      ].startTime = action.payload?.start_time;
    },
    setCourseScheduleDurationSlots: (state, action) => {
      state.courseScheduleSlots[action.payload.day].timeSlots[
        action.payload.index
      ].durationMinutes = action.payload?.duration;
    },
    setCourseScheduleFrequency: (state, action) => {
      state.courseScheduleSlots[action.payload.day].recurrence_frequency =
        action.payload?.recurrence_frequency;
    },
    updateStrandsSortFilters: (state, action) => {
      state.strandsFilters = action.payload.filter;
      state.StrandsSorting = action.payload.sorting;
    },
    setClassCourseStrandsColumnSearch: (state, action) => {
      state.updateStrandsSortFilters = action.payload;
    },
    setClassCourseStrands: (state, action) => {
      state.classCourseStrandsIds = action.payload;
    },
    removeCourseScheduleDurationSlots: (state, action) => {
      action.payload.forEach((day) => {
        if (state.courseScheduleSlots[day]) {
          delete state.courseScheduleSlots[day];
        }
      });
    },
    TagClassCourseStrandsToOtherDrawer: (state, action) => {
      if (action.payload.status === false) {
        state.ClassCourseStrandsButtonSpinner = false;
      }
      state.TagClassCourseStrandsToOtherDrawerStatus = action.payload.status;
    },
    setSelectCourseStrands: (state, action) => {
      state.selectCourseStrands = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveLessonTopic.pending, (state, action) => {
        state.ClassCourseButtonSpinner = true;
      })
      .addCase(saveLessonTopic.fulfilled, (state, action) => {
        state.ClassCourseButtonSpinner = false;
      })
      .addCase(saveLessonTopic.rejected, (state, action) => {
        state.ClassCourseButtonSpinner = false;
      })
      .addCase(sendEmail.pending, (state, action) => {
        state.ConversationButtonSpinner = true;
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.ConversationButtonSpinner = false;
        state.ConversationErrors = {};
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.ConversationShowMessage = true;
        state.ConversationButtonSpinner = false;
        state.ConversationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(saveErs.pending, (state, action) => {
        state.ErsButtonSpinner = true;
      })
      .addCase(saveErs.fulfilled, (state, action) => {
        state.ErsButtonSpinner = false;
        state.ErsErrors = {};
      })
      .addCase(saveErs.rejected, (state, action) => {
        state.ErsShowMessage = true;
        state.ErsButtonSpinner = false;
        state.ErsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(calenderEvent.pending, (state, action) => {
        state.ClassCourseScheduleActivityButtonSpinner = true;
      })
      .addCase(calenderEvent.fulfilled, (state, action) => {
        state.ClassCourseScheduleActivityButtonSpinner = false;
        state.ClassCourseScheduleActivityErrors = {};
        state.ClassCoursesData = action.payload?.data?.class_courses;
        state.classCoursesSchedule = action.payload?.data?.courseSchedule;

        state.ViewManageClassData = {
          ...state.ViewManageClassData,
          class_courses_count: action.payload?.data?.class_courses_count,
          class_courses_teachers: action.payload?.data?.class_courses_teachers,
        };
      })
      .addCase(calenderEvent.rejected, (state, action) => {
        state.ClassCourseScheduleActivityShowMessage = true;
        state.ClassCourseScheduleActivityButtonSpinner = false;
        state.ClassCourseScheduleActivityErrors = JSON.parse(
          action.error.message
        ); // Parse the error messages and store them in the state
      })
      .addCase(createManageClass.pending, (state, action) => {
        state.ManageClassButtonSpinner = true;
      })
      .addCase(createManageClass.fulfilled, (state, action) => {
        state.ManageClassButtonSpinner = false;
        state.ManageClassErrors = {};
      })
      .addCase(createManageClass.rejected, (state, action) => {
        state.ManageClassShowMessage = true;
        state.ManageClassButtonSpinner = false;
        state.ManageClassErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(saveGoogleToken.rejected, (state, action) => {})
      .addCase(createClassCourse.pending, (state, action) => {
        state.ClassCourseButtonSpinner = true;
        state.ClassCourseScheduleButtonSpinner = true;
      })
      .addCase(createClassCourse.fulfilled, (state, action) => {
        state.ClassCourseScheduleButtonSpinner = false;
        state.ClassCourseButtonSpinner = false;
        state.ClassCourseErrors = {};
        state.ClassCoursesData = action.payload?.data?.class_courses;
        state.classCoursesSchedule = action.payload?.data?.courseSchedule;

        state.ViewManageClassData = {
          ...state.ViewManageClassData,
          class_courses_count: action.payload?.data?.class_courses_count,
          class_courses_teachers: action.payload?.data?.class_courses_teachers,
        };
      })
      .addCase(createClassCourse.rejected, (state, action) => {
        state.ClassCourseShowMessage = true;
        state.ClassCourseButtonSpinner = false;
        state.ClassCourseScheduleButtonSpinner = false;
        state.ClassCourseErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(createClassResource.pending, (state, action) => {
        state.ClassResourceButtonSpinner = true;
      })
      .addCase(createClassResource.fulfilled, (state, action) => {
        state.ClassResourceButtonSpinner = false;
        state.ClassResourceErrors = {};
        state.ClassResourceData = action.payload?.data;

        state.ViewManageClassData = {
          ...state.ViewManageClassData,
        };
      })
      .addCase(createClassResource.rejected, (state, action) => {
        state.ClassResourceShowMessage = true;
        state.ClassResourceButtonSpinner = false;
        state.ClassResourceErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(updateClassCourseWeightage.pending, (state, action) => {
        state.ClassCourseWeightageButtonSpinner = true;
      })
      .addCase(updateClassCourseWeightage.fulfilled, (state, action) => {
        state.ClassCourseWeightageButtonSpinner = false;
        state.ClassCourseWeightageDrawer = false;
        state.ClassCourseErrors = {};
        state.ClassCoursesData = action.payload?.data?.class_courses;

        state.ViewManageClassData = {
          ...state.ViewManageClassData,
          class_courses_count: action.payload?.data?.class_courses_count,
          class_courses_teachers: action.payload?.data?.class_courses_teachers,
        };
      })
      .addCase(updateClassCourseWeightage.rejected, (state, action) => {
        state.ClassCourseShowMessage = true;
        state.ClassCourseWeightageButtonSpinner = false;
        state.ClassCourseErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(addClassCourseStrands.pending, (state, action) => {
        state.ClassCourseStrandsButtonSpinner = true;
      })
      .addCase(addClassCourseStrands.fulfilled, (state, action) => {
        state.ClassCourseStrandsButtonSpinner = false;
        state.ClassCourseStrandErrors = {};
        state.ClassCoursesData = action.payload?.data?.class_courses;

        state.ViewManageClassData = {
          ...state.ViewManageClassData,
          class_courses_count: action.payload?.data?.class_courses_count,
          class_courses_teachers: action.payload?.data?.class_courses_teachers,
        };
      })
      .addCase(addClassCourseStrands.rejected, (state, action) => {
        state.ClassCourseShowMessage = true;
        state.ClassCourseStrandsButtonSpinner = false;
        state.ClassCourseStrandErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(classCourseFilter.pending, (state, action) => {
        state.classCourseLoader = true;
      })
      .addCase(classCourseFilter.fulfilled, (state, action) => {
        state.classCourseLoader = false;
        state.ClassCoursesData = action.payload;
      })
      .addCase(classCourseFilter.rejected, (state, action) => {
        state.classCourseLoader = false;
      })

      .addCase(classStudentFilter.pending, (state, action) => {
        state.classStudentLoader = true;
      })
      .addCase(classStudentFilter.fulfilled, (state, action) => {
        state.classStudentLoader = false;
        state.ClassStudentsData = action.payload;
      })
      .addCase(classStudentFilter.rejected, (state, action) => {
        state.classStudentLoader = false;
      })

      .addCase(updateCourseStrandsSort.pending, (state, action) => {
        state.ClassCourseStrandsTableLoading = true;
      })
      .addCase(updateCourseStrandsSort.fulfilled, (state, action) => {
        state.ClassCourseStrandsTableLoading = false;
      })
      .addCase(updateCourseStrandsSort.rejected, (state, action) => {
        state.ClassCourseStrandsTableLoading = false;
      })

      .addCase(addClassStudent.pending, (state, action) => {
        // state.ClassStudentButtonSpinner = true;
      })
      .addCase(addClassStudent.fulfilled, (state, action) => {
        state.ClassStudentButtonSpinner = false;
        state.ClassStudentErrors = {};
        state.ClassStudentsData = action.payload?.data?.class_student;
        state.ViewManageClassData = {
          ...state.ViewManageClassData,
          class_student_count: action.payload?.data?.class_student_count,
        };
      })
      .addCase(addClassStudent.rejected, (state, action) => {
        state.ClassStudentShowMessage = true;
        state.ClassStudentButtonSpinner = false;
        state.ClassStudentErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(deleteClassStudent.pending, (state, action) => {})
      .addCase(deleteClassStudent.fulfilled, (state, action) => {
        if (action.payload.status !== false) {
          state.ClassStudentsData = action.payload?.data?.class_student;
          state.ViewManageClassData = {
            ...state.ViewManageClassData,
            class_student_count: action.payload?.data?.class_student_count,
          };
        }
      })
      .addCase(deleteClassStudent.rejected, (state, action) => {})

      .addCase(deleteClassCourse.pending, (state, action) => {})
      .addCase(deleteClassCourse.fulfilled, (state, action) => {
        if (action.payload.status !== false) {
          state.ClassCoursesData = action.payload?.data?.class_courses;
          state.classCoursesSchedule = action.payload?.data?.courseSchedule;
          state.ViewManageClassData = {
            ...state.ViewManageClassData,
            class_courses_count: action.payload?.data?.class_courses_count,
            class_courses_teachers:
              action.payload?.data?.class_courses_teachers,
          };
        }
      })
      .addCase(deleteClassCourse.rejected, (state, action) => {})

      .addCase(deleteClassCourseTeacher.pending, (state, action) => {})
      .addCase(deleteClassCourseTeacher.fulfilled, (state, action) => {
        if (action.payload.status !== false) {
          state.ClassCoursesData = action.payload?.data?.class_courses;
          state.ViewManageClassData = {
            ...state.ViewManageClassData,
            class_courses_count: action.payload?.data?.class_courses_count,
            class_courses_teachers:
              action.payload?.data?.class_courses_teachers,
          };
        }
      })
      .addCase(deleteClassCourseTeacher.rejected, (state, action) => {})

      .addCase(deleteClassResource.pending, (state, action) => {})
      .addCase(deleteClassResource.fulfilled, (state, action) => {
        if (action.payload.status !== false) {
          state.ClassResourceData = action.payload?.data;
          state.ViewManageClassData = {
            ...state.ViewManageClassData,
          };
        }
      })
      .addCase(deleteClassResource.rejected, (state, action) => {})

      .addCase(deleteClassCourseStrands.pending, (state, action) => {})
      .addCase(deleteClassCourseStrands.fulfilled, (state, action) => {})
      .addCase(deleteClassCourseStrands.rejected, (state, action) => {})

      .addCase(getOtherClasses.pending, (state, action) => {})
      .addCase(getOtherClasses.fulfilled, (state, action) => {
        state.OtherClassesData = action.payload;
      })
      .addCase(getOtherClasses.rejected, (state, action) => {})

      .addCase(getGradeStudents.pending, (state, action) => {})
      .addCase(getGradeStudents.fulfilled, (state, action) => {
        state.gradeStudents = action.payload;
      })
      .addCase(getGradeStudents.rejected, (state, action) => {})

      .addCase(getOtherClassStudent.pending, (state, action) => {})
      .addCase(getOtherClassStudent.fulfilled, (state, action) => {
        state.OtherClassStudents = action.payload;
      })
      .addCase(getOtherClassStudent.rejected, (state, action) => {})

      .addCase(getClassCourseStrands.pending, (state, action) => {
        state.ClassCourseStrandsTableLoading = true;
      })
      .addCase(getClassCourseStrands.fulfilled, (state, action) => {
        state.ClassCourseStrandsTableLoading = false;
        state.classCourseStrands = action.payload;
        state.strandsTablePagination = {
          ...state.strandsTablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page,
        };
      })
      .addCase(getErs.rejected, (state, action) => {
        state.ErsTableLoading = false;
      })

      .addCase(getErs.pending, (state, action) => {
        state.ErsTableLoading = true;
      })
      .addCase(getErs.fulfilled, (state, action) => {
        state.ErsTableLoading = false;
        state.Ers = action.payload;
        state.ErsTablePagination = {
          ...state.ErsTablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page,
        };
      })
      .addCase(getClassCourseStrands.rejected, (state, action) => {
        state.ErsTablePagination = false;
      })

      .addCase(getManageClass.pending, (state, action) => {
        state.ManageClassButtonSpinner = true;
        state.ManageClassTableLoading = true;
      })
      .addCase(getManageClass.fulfilled, (state, action) => {
        state.ManageClassButtonSpinner = false;
        state.ManageClassTableLoading = false;
        state.ManageClassResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page,
        };
      })
      .addCase(getManageClass.rejected, (state, action) => {
        state.ManageClassShowMessage = true; // Set the showMessage flag to display the errors
        state.ManageClassButtonSpinner = false;
        state.ManageClassTableLoading = false;
        state.ManageClassErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getTaggedClassCourseTeachers.pending, (state, action) => {})
      .addCase(getTaggedClassCourseTeachers.fulfilled, (state, action) => {
        state.taggedClassCourseTeachers = action.payload;
      })
      .addCase(getTaggedClassCourseTeachers.rejected, (state, action) => {})

      .addCase(getTaggedClassResources.pending, (state, action) => {})
      .addCase(getTaggedClassResources.fulfilled, (state, action) => {
        state.taggedClassCourseResources = action.payload;
      })
      .addCase(getTaggedClassResources.rejected, (state, action) => {})

      .addCase(get_class_courses.pending, (state, action) => {})
      .addCase(get_class_courses.fulfilled, (state, action) => {
        state.ClassCourses = action.payload;
      })
      .addCase(get_class_courses.rejected, (state, action) => {})

      .addCase(getClassCourseData.pending, (state, action) => {})
      .addCase(getClassCourseData.fulfilled, (state, action) => {
        state.course_schedule = action?.payload
          ? action?.payload[0]?.course_schedule
          : [];
        state.classCourseSchedule = action?.payload
          ? action?.payload[0]?.course_schedule
          : [];
      })
      .addCase(getClassCourseData.rejected, (state, action) => {})

      .addCase(viewManageClass.pending, (state, action) => {
        state.ViewManageClassLoader = true;
        state.ViewManageClassData = [];
      })
      .addCase(viewManageClass.fulfilled, (state, action) => {
        state.ViewManageClassLoader = false;
        state.ClassStudentsData = action.payload?.class_student;
        state.ClassCoursesData = action.payload?.class_courses;
        // state.ViewManageClassData = action.payload
        state.classCoursesSchedule = action.payload?.courseSchedule;
        state.ViewManageClassData = {
          ...state.ViewManageClassData,
          class_courses_count: action.payload?.class_courses_count,
          class_student_count: action.payload?.class_student_count,
          enc_id: action.payload?.enc_id,
          end_date: action.payload?.end_date,
          grade_level: action.payload?.grade_level,
          id: action.payload?.id,
          is_same_room: action.payload?.is_same_room,
          label: action.payload?.label,
          name: action.payload?.name,
          teacher_id: action.payload?.teacher_id,
          courseSchedule: action.payload?.courseSchedule,
          home_teacher_name: action.payload?.home_teacher_name,
          class_courses_teachers: action.payload?.class_courses_teachers,
          organization_grade_level_id:
            action.payload?.organization_grade_level_id,
          organization_id: action.payload?.organization_id,
          school_room: action.payload?.school_room,
          school_room_id: action.payload?.school_room_id,
          school_year: action.payload?.school_year,
          school_year_id: action.payload?.school_year_id,
          start_date: action.payload?.start_date,
          totalDayLefts: action.payload?.totalDayLefts,
          value: action.payload?.value,
        };
      })
      .addCase(viewManageClass.rejected, (state, action) => {
        state.ViewManageClassLoader = false;
        state.ViewManageClassData = [];
      })

      .addCase(deleteManageClass.pending, (state, action) => {})
      .addCase(deleteManageClass.fulfilled, (state, action) => {})
      .addCase(deleteManageClass.rejected, (state, action) => {})

      .addCase(getStrandsCourses.pending, (state, action) => {
        state.ClassCourseStrandsLoading = true;
      })
      .addCase(getStrandsCourses.fulfilled, (state, action) => {
        state.ClassCourseStrandsLoading = false;
        state.strandsCourses = action.payload;
      })
      .addCase(getStrandsCourses.rejected, (state, action) => {
        state.ClassCourseStrandsLoading = false;
      })

      .addCase(getClassCourseWithoutStrand.pending, (state, action) => {
        state.ClassCourseWithoutStrandsLoading = true;
      })
      .addCase(getClassCourseWithoutStrand.fulfilled, (state, action) => {
        state.ClassCourseWithoutStrandsLoading = false;
        state.withoutStrandsCourses = action.payload;
      })
      .addCase(getClassCourseWithoutStrand.rejected, (state, action) => {
        state.ClassCourseWithoutStrandsLoading = false;
      })

      .addCase(saveClassCoursesStrands.pending, (state, action) => {
        state.ClassCourseStrandsButtonSpinner = true;
        state.SaveClassCourseStrandsLoading = true;
      })
      .addCase(saveClassCoursesStrands.fulfilled, (state, action) => {
        state.SaveClassCourseStrandsLoading = false;
        state.ClassCourseStrandsButtonSpinner = false;
        state.TagClassCourseStrandErrors = {};
        state.ClassCoursesData = action.payload?.data?.class_courses;

        state.ViewManageClassData = {
          ...state.ViewManageClassData,
          class_courses_count: action.payload?.data?.class_courses_count,
          class_courses_teachers: action.payload?.data?.class_courses_teachers,
        };
      })
      .addCase(saveClassCoursesStrands.rejected, (state, action) => {
        state.ClassCourseStrandsButtonSpinner = false;
        state.SaveClassCourseStrandsLoading = false;
      });
  },
});

export const {
  updateClassCourseString,
  updateClassStudentString,
  ClassCourseAssignmentDrawerStatus,
  ClassCourseGradeDrawerStatus,
  ClassCourseLessonDrawerStatus,
  ClassCourseScheduleDrawerStatus,
  ClassCourseViewDrawerStatus,
  ClassStudentDrawerStatus,
  ClassCourseDrawerStatus,
  setSelectCourseStrands,
  ClassCourseScheduleStatus,
  ClassResourceDrawerStatus,
  AttendanceDrawerStatus,
  ClassOverviewDrawerStatus,
  ManageClassAddDrawerStatus,
  ClassCourseWeightageDrawerStatus,
  ClassCourseStrandsDrawerStatus,
  ManageClassEditWithDrawerStatus,
  updateSortFilters,
  updateStrandsSortFilters,
  setSelectedRowKeys,
  setCourseScheduleFrequency,
  setCourseScheduleTimeSlots,
  setCourseScheduleDurationSlots,
  ManageConversationDrawerStatus,
  ManageErsDrawerStatus,
  setConversationAttachment,
  setClassCourseStrandsColumnSearch,
  setTeacherCourses,
  resetStates,
  setClassCourseStrands,
  setClassCourseSchedule,
  removeCourseScheduleDurationSlots,
  setSelectedLessonPlan,
  TagClassCourseStrandsToOtherDrawer,
  setCourseScheduleSlots,
} = manageManageClassSlice.actions;

export default manageManageClassSlice.reducer;
